import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

export const initFirebase = () => {
  // Configurações: https://console.firebase.google.com/u/0/project/quiteja/settings/general
  let app = initializeApp({
    appId: "1:1059483522324:web:7f8f1f8aa907df73d41e01",
    apiKey: "AIzaSyCvJb46typiL47QT5HCupHFj8UVJUMvj9Q",
    messagingSenderId: "1059483522324",
    projectId: "quiteja",
  });

  console.debug("Firebase:", app.name);

  // Pega uma instância do Firebase Messaging para poder tratar mensagens em background
  try {
    const messaging = getMessaging(app);

    if (messaging) {
      console.debug("Firebase Messaging habilitado.");
    }
  } catch (err) {
    console.debug("Não foi possível iniciar o Firebase Messaging.", err);
  }
}
